<template lang="pug">
  .wrapper
    header
      .logo(@click="home")
    .content
      a 炒肉加点它，不柴不老味道好
      a 常吃大蒜好处多，它的神奇功效你知道吗？
      a 炖肉加了它，满屋飘香
      a 豆腐这样做，连吃七天也不腻
      a 羊肉只能涮？这样做不腥不膻特解馋
      a 两颗土豆这样做，给肉也不换
      a 鸡肉做法大全，你想吃的鸡肉做法都在这
      a 香肠能自己做吗？掌握了这个秘诀再也吃淀粉肠了
      a 炒素菜做好这两步，炒什么什么香
      a 为什么你炖肉不香？让人魂牵梦绕的肉味到底怎么来的？
      a 不和面不发面，馅饼这样做最简单
      a 不时不食，春季吃什么最好？
      a 不时不食，夏季吃什么最好？
      a 不时不食，秋季吃什么最好？
      a 不时不食，冬季吃什么最好？
      a 成本几块钱，这样做的萝卜泡菜怎么也吃不够
    pageFooter
</template>

<script>
const pageFooter = () => import('@/components/pageFooter')
export default {
  components: {
    pageFooter
  },
  mounted() {
    const link = document.querySelectorAll('a')
    link.forEach(a => {
      a.addEventListener('click', e => {
        e.preventDefault()
        alert('付费后方可观看，每条2元！')
      })
    })
  },
  methods: {
    home() {
      this.$router.push('/pages/Information')
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    header {
      display: flex;
      justify-content: space-between;
      padding: .1rem;
      width: 100%;
      color: #42b983;
      text-align: left;
      .logo {
        display: inline-block;
        width: 1rem;
        height: .5rem;
        background: url(https://www.woxiaojiang.com/oss/sources/logo.png) no-repeat;
        background-size: contain;
      }
      /deep/ .el-menu--horizontal>.el-menu-item {
        height: .4rem;
        line-height: .4rem;
      }
    }
    .content {
      padding: .2rem .3rem .5rem;
      width: 100%;
      min-height: 5rem;
      font-size: .11rem;
      counter-reset: sn;
      a {
        display: block;
        width: 100%;
        line-height: .3rem;
        color: #91d5ff;
        counter-increment: sn;
        &:before {
          content: counter(sn)'. ';
        }
      }
    }
  }
</style>
